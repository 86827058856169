import React, { useEffect } from 'react'
import Navbar2 from '../components/Navbar2'
import ProductCard from '../components/ProductCard'

function Products({openNav,setOpenNav,isAuth, signout, products, getProducts}) {


    useEffect( () =>{
        getProducts()
    },[])

    useEffect(() => {
        if (openNav) {
          document.body.classList.add('hide');
        } else {
          document.body.classList.remove('hide');
        }
    
        // Clean up the class on unmount
        return () => {
          document.body.classList.remove('hide');
        };
      }, [openNav]);



  return (
    <div className='flex flex-col items-center w-screen mt-[100px]' >
        <Navbar2 openNav={openNav} setOpenNav={setOpenNav} isAuth={isAuth} signout={signout} ></Navbar2>
        <div className='w-[90vw] flex flex-col gap-6 items-center ' >
            <h1 className='text-center  my-6 font-bold text-[24px] sm:text-[26px] md:text-[28px] lg:text-[32px] xl:text-[36px] tracking-[3px]' >Nos Produits</h1>
            <div className='flex flex-wrap justify-center w-full gap-4 mx-auto' >
                {products.map(product=>{
                    return(
                        <ProductCard title={product.titre} price={product.prix} description={product.description} images={product.images} id={product.id} reviews={product.reviews} />
                    )
                })}
            </div>
        </div>
    </div>
  )
}

export default Products