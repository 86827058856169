import React from 'react'
import '../App.css'
import { Link } from 'react-router-dom'


function DashboardCard({title, price, devise, description, images, id, deleteProduct , setActive}) {
  return (
    <div className='h-[450px] w-[320px] rounded-[20px] bg-white shadow overflow-hidden ' >
        <div className='flex flex-col items-center justify-center h-full gap-4 ' >
          <img src={images[0]} alt="" className='w-full h-[55%]  object-contain' />
          <div className='flex flex-col items-center gap-5 ' >
            <h1 className='font-semibold text-[24px] tracking-[2px] text-center text-[#1E89E4]' >{title}</h1>
            <p className='text-black font-medium text-[20px] text-center' >{price} {devise}</p>
          </div>
          <div className='flex gap-3 w-[90%]' >
            <Link to={`/Dashboard/${id}`} onClick={() => setActive("4")} className='flex justify-center items-center w-[130px] h-[40px] bg-[#ffe4cb] text-[#fc792f] rounded-[10px]' >Modifier</Link>
            <button onClick={() => deleteProduct(id)} className='flex justify-center items-center w-[130px] h-[40px] bg-[#ffd8d4] text-[#fc2c16] rounded-[10px]' >Supprimer</button>
          </div>

        </div>
    </div>
  )
}

export default DashboardCard

