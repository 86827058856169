import React, { useState, useRef, useEffect } from 'react'
import Navbar2 from '../components/Navbar2'
import '../App.css'
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, sendEmailVerification, signOut, sendPasswordResetEmail } from 'firebase/auth'
import {auth, db} from '../config/firebase'
import { getDocs, collection, addDoc, getDoc, doc } from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Login({openNav,setOpenNav,setIsAuth, isAuth, email, setEmail, password, setPassword, loginError, setLoginError, signout}) {

    const navigate = useNavigate()
    const [seConnecter, setSeConnecter] = useState(true)
    const [enregistrer, setEnregistrer] = useState(false)
    const [passwordVer, setPasswordVer] = useState('')
    const [validEmail, setValidEmail] = useState(false);
    const emailRef = useRef(null)
    const passwordRef = useRef(null)
    const passwordVerRef = useRef(null)
    const usersRef= collection(db, 'users')
    const [nom, setNom] = useState('')
    const [prenom, setPrenom] = useState('')
    const addUser = async (id) => {
        await addDoc(usersRef, {nom : nom, prenom : prenom, id : id, email: email, role:"user"})
      }
    const register = async () => {
        if(nom){
            if(prenom){
                if(validEmail){
                    if(password && password.length>5){
                        if(password===passwordVer){
                            try {
                                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                                await sendEmailVerification(userCredential.user);
                                localStorage.setItem("isAuth", 'true')
                                localStorage.setItem("user", JSON.stringify(userCredential.user))
                                setIsAuth(true)
                                addUser(userCredential.user.uid)
                                console.log(userCredential.user.emailVerified)
                            }
                                catch(e) {
                                console.error(e);
                                setLoginError(true)
                            }
                        }else{
                            setLoginError("Les mots de passe doivent etre identiques")
                        }
                    }else{
                        setLoginError("Le mot de passe doit avoir un minimum de 6 caractères ")
                    }
                }else{
                    setLoginError("Le format de l'email n'est pas correct")
                }
            }else{
                setLoginError("Le prenom ne peut pas etre vide")
            }
        }else{
            setLoginError("Le nom ne peut pas etre vide" )
        }
    }
    
    const login = async () =>{
        try{
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            localStorage.setItem("isAuth", 'true')
            localStorage.setItem("user", JSON.stringify(user))
            localStorage.setItem("isAuth", 'true')
            const data= await getDocs(usersRef)
            const filteredData = data.docs.map((doc) => (
                {
                    ...doc.data(),
                }
            ) )
            const userToFind = filteredData.find((i) => i.id === user.uid);
            if (userToFind.role==='admin'){
                localStorage.setItem("admin", "true")
            }
            navigate('/')
        }catch(e){
            console.error(e)
            setLoginError(true)
        }
    }

    const handleForgotPassword = async () => {
        try {
            // Send a password reset email to the user's email address
            if (email){
                await sendPasswordResetEmail(auth, email);
                toast.success(`Un e-mail pour reinitialiser votre mot de passe vient d'etre envoyé à ${email}` , {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }else{
                toast.error(`Remplissez votre E-mail pour pouvoir reinitialiser votre mot de passe` , {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        } catch (e) {
            toast.error(`E-mail incorrect` , {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            console.error(e);
        }
    }




    const handleConnecter = ()=>{
        setSeConnecter(true)
        setEnregistrer(false)
    }
    const handleEnregistrer = ()=>{
        setSeConnecter(false)
        setEnregistrer(true)
    }

    const handleEmailChange = (e) =>{
        const newEmail = e.target.value;
        setEmail(newEmail);
        // Regular expression to validate email format
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        setValidEmail(emailPattern.test(newEmail));
        setLoginError(false)
    }
    const handlePasswordChange = (e) =>{
        setPassword(e.target.value)
        setLoginError(false)
    }

    const handlePasswordVerChange = (e) =>{
        setPasswordVer(e.target.value)
        setLoginError(false)
    }
    const handleNomChange = (e) =>{
        setNom(e.target.value)
        setLoginError(false)
    }
    const handlePrenomChange = (e) =>{
        setPrenom(e.target.value)
        setLoginError(false)
    }

    useEffect(() => {
        if (openNav) {
          document.body.classList.add('hide');
        } else {
          document.body.classList.remove('hide');
        }
    
        // Clean up the class on unmount
        return () => {
          document.body.classList.remove('hide');
        };
      }, [openNav]);

  return (
    <div className='relative flex flex-col w-screen h-[120vh] lg:flex-row' >
        <Navbar2 openNav={openNav} setOpenNav={setOpenNav} isAuth={isAuth} signout={signout} ></Navbar2>
        <div className='w-screen lg:w-[50vw] h-[50vh] lg:h-full relative flex justify-center items-start lg:items-center' >
            <img src="/images/login.png" alt="" className='absolute inset-0 object-cover w-full h-full' />
            <div className='w-full h-full absolute inset-0 bg-[rgba(30,137,228,0.9)]' ></div>
            <p className='text-white text-center font-bold text-[22px] lg:text-[28px] xl:text-[32px] z-[90] mt-[120px] lg:mt-0' >Bienvenue chez INNOVALOGIE</p>
        </div>
        <div className='h-[50vh] lg:h-full w-screen lg:w-[50vw] bg-white' ></div>
        <div className='absolute top-[300px] lg:top-[50%] right-[50%] lg:right-[25vw] centerLog centerPC' >
            <div className='flex gap-2 absolute top-[-50px] left-0' >
                <button onClick={()=>handleConnecter()} className={`${seConnecter?'bg-[#1E89E4]':'bg-[#D9D9D9]'} rounded-t-[12px] flex justify-center items-center ${seConnecter?'text-[#fff]':'text-[#939393]'}  h-[50px] w-[120px] text-[14px]`} >Se connecter</button>
                <button onClick={()=>handleEnregistrer()} className={`${enregistrer?'bg-[#1E89E4]':'bg-[#D9D9D9]'} rounded-t-[12px] flex justify-center items-center ${enregistrer?'text-[#fff]':'text-[#939393]'} h-[50px] w-[120px] text-[14px]`} >S'enregistrer</button>
            </div>
            {seConnecter ? (
                        <div className='flex flex-col justify-center items-center gap-10 py-10 w-[350px] sm:w-[400px] md:w-[450px] rounded-r-[25px] rounded-bl-[25px] shadow bg-white relative' >
                            <p className='text-black font-semibold text-[26px]' >Se connecter</p>
                            <div className='flex flex-col gap-3' >
                                <p className='text-black text-[12px] w-[300px] text-center' >Re-bienvenue ! ravis de vous revoir.</p>
                                <p className='text-black text-[12px] w-[300px] text-center' >Connectez vous pour continuer votre experience</p>
                            </div>
                            <div className='flex flex-col gap-10' >
                                <div className='relative w-[300px]' >
                                    <input ref={emailRef} onChange={(e)=> handleEmailChange(e)} type="email" name="" id="" placeholder='E-mail' className='w-full h-[50px] relative  outline-none'/>
                                    <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                                </div>
                                <div className='relative w-[300px]' >
                                    <input ref={passwordRef} onChange={(e)=> handlePasswordChange(e)} type="password" name="" id="" placeholder='Mot de passe' className='w-full h-[50px] relative  outline-none'/>
                                    <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                                </div>
                            </div>
                            <p onClick={handleForgotPassword} className='text-[16px] text-[#1E89E4] lg:hover:underline cursor-pointer' >Mot de passe oublié</p>
                            {loginError && <p className='text-red-500 font-medium text-[16px] ' >Email ou mot de passe incorrect</p>}
                            <button onClick={()=> login()} className='w-[200px] h-[40px] flex justify-center items-center rounded-[20px] bg-[#1E89E4] text-white hover:bg-white border border-transparent hover:border-[#1E89E4] hover:text-[#1E89E4] transition-all duration-300' >Se connecter</button>
                        </div>
            ):(!isAuth ?

            <div className='flex flex-col justify-center items-center gap-7 py-10 w-[350px] sm:w-[400px] md:w-[450px] rounded-r-[25px] rounded-bl-[25px] shadow bg-white relative' >
                <p className='text-black font-semibold text-[26px]' >S'enregistrer</p>
                <div className='flex flex-col gap-3' >
                    <p className='text-black text-[12px] w-[300px] text-center' >Bienvenue chez Innovalogie</p>
                    <p className='text-black text-[12px] w-[300px] text-center' >Créez votre compte pour améliorer votre experience</p>
                </div>
                <div className='flex flex-col gap-9' >
                    <div className='relative text-[14px] w-[300px]' >
                        <input ref={emailRef} onChange={(e)=> handleNomChange(e)} type="text" name="" id="" placeholder='Nom' className='w-full h-[50px] relative  outline-none'/>
                        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                    </div>
                    <div className='relative text-[14px] w-[300px]' >
                        <input ref={emailRef} onChange={(e)=> handlePrenomChange(e)} type="text" name="" id="" placeholder='Prenom' className='w-full h-[50px] relative  outline-none'/>
                        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                    </div>
                    <div className='relative text-[14px] w-[300px]' >
                        <input ref={emailRef} onChange={(e)=> handleEmailChange(e)} type="email" name="" id="" placeholder='E-mail' className='w-full h-[50px] relative  outline-none'/>
                        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                    </div>
                    <div className='relative text-[14px] w-[300px]' >
                        <input ref={passwordRef} onChange={(e)=> handlePasswordChange(e)}  type="password" name="" id="" placeholder='Mot de passe' className='w-full h-[50px] relative  outline-none'/>
                        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                    </div>
                    <div className='relative text-[14px] w-[300px]' >
                        <input ref={passwordVerRef} onChange={(e)=> handlePasswordVerChange(e)} type="password" name="" id="" placeholder='Confirmez votre mot de passe' className='w-full h-[50px] relative  outline-none'/>
                        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                    </div>
                </div>
                {loginError && <p className='text-red-500 font-medium text-[16px] text-center' >{loginError}</p>}
                <button onClick={()=> register()} className='w-[200px] h-[40px] flex justify-center items-center rounded-[20px] bg-[#1E89E4] text-white hover:bg-white border border-transparent hover:border-[#1E89E4] hover:text-[#1E89E4] transition-all duration-300' >S'enregistrer</button>
            </div>:
                <div className='flex flex-col justify-center items-center  py-10 h-[400px] px-2 lg:px-4 leading-[30px] w-[350px] sm:w-[400px] md:w-[450px] rounded-r-[25px] rounded-bl-[25px] shadow bg-white relative' >
                    <p className='text-center text-black font-normal text-[16px]' >Merci d'avoir rejoint Innovalogie ! Veuillez activer votre compte en cliquant sur le lien envoyé à votre adresse e-mail (possible que le message part dans les spam) </p>
                </div>
            )
        }

        </div>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />

    </div>
  )
}

export default Login