import React from 'react'

function Innovation() {
  return (
    <div className='flex flex-col justify-center items-center gap-6 lg:gap-10 xl:gap-12 p-2 mt-[80px] lg:mt-[130px]' >
        <p className='text-center text-black font-bold text-[24px] sm:text-[32px] md:text-[36px] lg:text-[44px] xl:text-[48px]' ><span className='text-[36px] sm:text-[40px] md:text-[48px] lg:text-[50px] xl:text-[52px] text-[#1E89E4]' >"</span>L'innovation par l'alliance<span className='text-[36px] sm:text-[40px] md:text-[48px] lg:text-[50px] xl:text-[52px] text-[#1E89E4]' >"</span></p>
        <div className='flex flex-col gap-5 lg:gap-8 xl:gap-12 sm:w-[450px] md:w-[650px] lg:w-[800px]' >
            <p className='text-[12px] sm:text-[14px] lg:text-[18px] font-semibold text-center lg:leading-[32px]' ><span className='font-bold text-[#1E89E4]' >INNOVALOGIE</span> est une structure jeune, dont les managers sont issus des domaines du génie électronique et du génie mécanique.</p>
            <p className='text-[12px] sm:text-[14px] lg:text-[18px] font-semibold text-center lg:leading-[32px]' >Avec des expériences acquises aussi bien en Algérie qu’à l’international, les quatre membres de <span className='font-bold text-[#1E89E4]' >INNOVALOGIE</span> ont travaillé avec les acteurs majeurs du transport, de l’aéronautique et de la défense</p>
        </div>
        {/* <div className='flex items-center justify-center gap-6 mt-6 sm:gap-8 md:gap-10 lg:gap-14' >
            <img src="/images/mercedes.png" alt="" className='h-10 sm:h-12 lg:h-14' />
            <img src="/images/renault.png" alt="" className='h-10 sm:h-12 lg:h-14' />
            <img src="/images/peugeot.png" alt="" className='h-10 sm:h-12 lg:h-14' />
            <img src="/images/sncf.png" alt="" className='h-10 sm:h-12 lg:h-14' />
        </div> */}
    </div>
  )
}

export default Innovation