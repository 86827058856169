import React, { useEffect, useState, useRef } from 'react'
import Navbar2 from '../components/Navbar2'
import '../App.css'
import {getDocs, collection } from 'firebase/firestore'
import {db} from '../config/firebase'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';



function Form({openNav,setOpenNav,isAuth, signout}) {

    const [nom, setNom]= useState('')
    const [prenom, setPrenom]= useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [openDrop, setOpenDrop] = useState(false)
    const [object, setObject] = useState('Objet')
    const [autre, setAutre] = useState('')
    const [formError, setFormError] = useState('')
    const usersRef= collection(db, 'users')
    const nomRef = useRef(null)
    const prenomRef = useRef(null)
    const messageRef = useRef(null)
    const navigate = useNavigate()
    

    const getUser = async (id)=>{
        const data= await getDocs(usersRef)
        const filteredData = data.docs.map((doc) => (
            {
                ...doc.data(),
            }
        ) )
        const user = filteredData.find((user) => user.id === id);
        setNom(user?.nom)
        setPrenom(user?.prenom)
    }

    useEffect(()=>{
        getUser(JSON.parse(localStorage.getItem("user"))?.uid)
        const user = localStorage.getItem("user")
        if (user!== null && user!== undefined){
            setEmail(JSON.parse(localStorage.getItem("user"))?.email)
        }
    }
        
        , [])

    useEffect(() => {
        if (openNav) {
        document.body.classList.add('hide');
        } else {
        document.body.classList.remove('hide');
        }
    
        // Clean up the class on unmount
        return () => {
        document.body.classList.remove('hide');
        };
    }, [openNav]);




    const handleNomChange = (e) =>{
        if (!localStorage.getItem("user")){
            setNom(e.target.value)
        }
    }

    const handlePrenomChange = (e) =>{
        if (!localStorage.getItem("user")){
            setPrenom(e.target.value)
        }
    }

    const sendEmail = async ()=>{
        try {
          const response = await axios.post('https://innovalogie.onrender.com/send-email', {
            from: "wassim.mouloud11@gmail.com",
            to: 'contact@innovalogie.com',
            subject: autre?autre:object,
            html: `
            <p><strong>Nom:</strong> ${nom}</p>
            <p><strong>Prénom:</strong> ${prenom}</p>
            <p><strong>E-mail:</strong> ${email}</p>
            <p><strong>Message:</strong> ${message}</p>
          `,
            });
    
          navigate("/ReceiveEmail")
        } catch (error) {
          console.error('Error sending email:', error.message);
        }
      }

    



  return (
    <div className='relative flex flex-col w-screen h-[140vh] lg:h-[120vh]' >
        <Navbar2 openNav={openNav} setOpenNav={setOpenNav} isAuth={isAuth} signout={signout} ></Navbar2>
        <div className='w-screen h-[40%] relative flex justify-center items-start lg:items-center' >
            <img src="/images/login.png" alt="" className='absolute inset-0 object-cover w-full h-full' />
            <div className='w-full h-full absolute inset-0 bg-[rgba(30,137,228,0.9)]' ></div>
        </div>
        <div className='h-[60%] w-screen lg:w-[50vw] bg-white' ></div>
        <div className='py-14 absolute w-[90vw] md:w-[700px] height-tr rounded-[30px] shadow top-[50%] left-[50%] centerAll bg-white flex flex-col justify-center items-center gap-8' >
            <p className='text-black font-semibold text-[32px] lg:text-[36px] text-center' >Nous Contacter</p>
            <div  className='flex flex-col gap-10' >
                <div className='relative' >
                    <input name="name" ref={nomRef} onChange={(e) => handleNomChange(e) }   type="text"  id="" placeholder='Nom' value={JSON.parse(localStorage.getItem("user"))?.uid && nom} className='w-[260px] sm:w-[320px] md:w-[360px] h-[50px] relative  outline-none'/>
                    <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                </div>
                <div className='relative' >
                    <input name="prenom" ref= {prenomRef} onChange={(e) => handlePrenomChange(e) }   type="text"  id="" placeholder='Prenom' value={JSON.parse(localStorage.getItem("user"))?.uid && prenom} className='w-[260px] sm:w-[320px] md:w-[360px] h-[50px] relative  outline-none'/>
                    <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                </div>
                <div className='relative' >
                    <input name="email" onChange={(e)=> setEmail(e.target.value)} type="text"  id="" placeholder='E-mail' value={JSON.parse(localStorage.getItem("user"))?.email} className='w-[260px] sm:w-[320px] md:w-[360px] h-[50px] relative  outline-none'/>
                    <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                </div>
                <div className='flex flex-col gap-1 cursor-pointer' >
                    <div onClick={() => setOpenDrop(!openDrop)} className='w-[260px] sm:w-[320px] md:w-[360px] h-[50px] border border-[#1E89E4] rounded-[10px] flex items-center justify-between p-4 ' >
                        <p>{object}</p>
                        <img src="/images/dropdown.png" alt="" className='w-6' />
                    </div>
                    <div className={`flex flex-col gap-2 w-[260px] sm:w-[320px] md:w-[360px] border border-[#1E89E4] rounded-[10px] py-4  ${openDrop?'h-auto':'hidden h-0 '}`} >
                        <p onClick={() => {
                            setOpenDrop(false)
                            setObject('Achat')
                        }} className='text-[#1E89E4] hover:bg-[rgba(30,136,228,0.43)] h-[30px] flex items-center px-4' >Achat</p>
                        <p onClick={() => {
                            setOpenDrop(false)
                            setObject('Reclamation')
                        }} className='text-[#1E89E4] hover:bg-[rgba(30,136,228,0.43)] h-[30px] flex items-center px-4' >Reclamation</p>
                        <p onClick={() => {
                            setOpenDrop(false)
                            setObject('Retour')
                        }} className='text-[#1E89E4] hover:bg-[rgba(30,136,228,0.43)] h-[30px] flex items-center px-4' >Retour</p>
                        <p onClick={() => {
                            setOpenDrop(false)
                            setObject('Autre')
                        }} className='text-[#1E89E4] hover:bg-[rgba(30,136,228,0.43)] h-[30px] flex items-center px-4' >Autre</p>
                    </div>
                </div>
                
                {["Achat", "Reclamation", "Retour"].includes(object) && (
                        <input type="text" name="Objet" id="" value={object} className='hidden' />
                )}

                {object!=='Achat' && object!=='Reclamation' && object!=='Retour' && object!=='Objet' && (
                <div  className='relative' >
                    <input name='object' onChange={(e)=> setAutre(e.target.value)} type="text"  id="" placeholder='Objet' className='w-[260px] sm:w-[320px] md:w-[360px] h-[50px] relative  outline-none'/>
                    <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                </div>
                )}

                <div className='relative' >
                    <textarea name="message" ref={messageRef} onChange={(e)=> setMessage(e.target.value)} rows='5'  type="text"  id="" placeholder='Message' className='w-[260px] sm:w-[320px] md:w-[360px]  relative  outline-none'/>
                    <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                </div>
                {/* <input type="hidden" name="_captcha" value="false"></input>
                <input type="hidden" name="_next" value="https://innovalogie.com/" /> */}
                {formError && <p className='text-red-500 font-medium text-[16px] text-center' >{formError}</p>}
                <button onClick={()=> sendEmail()} type='submit' className='bg-[#1E89E4] hover:bg-white cursor-pointer border border-[#1E89E4] transition duration-300 rounded-md h-[40px] w-[200px] flex justify-center items-center text-white hover:text-[#1E89E4] mx-auto lg:mx-0' >Envoyer</button>
            </div>
        </div>

    </div>
  )
}

export default Form