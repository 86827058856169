import React, { useEffect, useState, useRef } from 'react'
import {useParams} from 'react-router-dom'
import { doc, getDoc, updateDoc, getDocs, collection } from "firebase/firestore";
import {db} from '../config/firebase'
import Navbar2 from '../components/Navbar2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../App.css'
import { auth } from '../config/firebase';
import {onAuthStateChanged } from 'firebase/auth';
import Avis from '../components/Avis';



function ProductDetail({openNav,setOpenNav,isAuth, signout, review, setReview}) {

    const [index, setIndex] = useState(0)
    const sliderRef = useRef(null)
    const {id}= useParams();
    const [product, setProduct] = useState({})
    const [rating, setRating] = useState(0)
    const [avis ,setAvis] = useState('')
    const [ratingError , setRatingError] = useState(false)
    const [loop, setLoop] = useState([1,2,3,4,5])
    const [loading, setLoading] = useState(false)
    const usersRef= collection(db, 'users')
    const [nom, setNom]= useState('')
    const [prenom, setPrenom]= useState('')
    const [userId, setUserId] = useState('')

  


  const handlePrev2 = () => {  
    if(index === 0){
      let size = sliderRef.current.offsetWidth;
      sliderRef.current.style.transition = 'transform 0.6s linear';
      let newIndex = product?.images?.length -1
      setIndex(newIndex);
      sliderRef.current.style.transform = 'translateX(' + (-size * newIndex) + 'px)';
  
    }else{
      let size = sliderRef.current.offsetWidth;
      let newIndex = index - 1;
      sliderRef.current.style.transition = 'transform 0.6s linear';
      setIndex(newIndex);
      sliderRef.current.style.transform = 'translateX(' + (-size * newIndex) + 'px)';
    }
  }

  const handleNext2 = () => {  
    if(index >= product?.images?.length -1){
      let size = sliderRef.current.offsetWidth;
      let newIndex = 0;
      sliderRef.current.style.transition = 'transform 0.6s linear';
      setIndex(newIndex);
      sliderRef.current.style.transform = 'translateX(' + (-size * newIndex) + 'px)';

    }else{
      let size = sliderRef.current.offsetWidth;
      let newIndex = index + 1;
      sliderRef.current.style.transition = 'transform 0.6s linear';
      setIndex(newIndex);
      sliderRef.current.style.transform = 'translateX(' + (-size * newIndex) + 'px)';
    }
}

    

  async function getDetail(){
      try{
          const docRef = doc(db, 'products', id)
          const docSnap = await getDoc(docRef)
          setProduct(docSnap.data())
      }catch(e){
          console.log("No such document!");
      }
  }

  
    useEffect(()=>{
        getDetail()

    }, [])

    useEffect(() =>{
        if(localStorage.getItem("user")){
            setUserId(JSON.parse(localStorage.getItem("user"))?.uid)
            getUser(JSON.parse(localStorage.getItem("user"))?.uid)
        }
    },[])

    useEffect(() => {
        if (openNav) {
          document.body.classList.add('hide');
        } else {
          document.body.classList.remove('hide');
        }
    
        // Clean up the class on unmount
        return () => {
          document.body.classList.remove('hide');
        };
      }, [openNav]);

    const closeReview = () =>{
        setReview(false)
        setRating(0)
        setRatingError(false)
    }

    const handleRating = (i) =>{
        setRating(i+1)
        setRatingError(false)
    }

    const sendReview = async () => {
        
      if (loading) return;
      setLoading(true);
      if (rating === 0) {
        setRatingError(true);
        setLoading(false);
        return; // Don't proceed with the update if the rating is 0
      }
    
      try {
        // Fetch the existing product data
        const productDoc = await getDoc(doc(db, 'products', id));
        if (productDoc.exists()) {
          const productData = productDoc.data();
      
          // Find the index of the user's existing review, if it exists
          const userReviewIndex = productData.reviews.findIndex((review) => review.id === userId);
      
          // Create a new review object
          const newReview = {
            nom: nom,
            prenom: prenom,
            note: rating,
            id: userId,
            avis: avis,
          };
      
          if (userReviewIndex !== -1) {
            // If the user has already sent a review, update the existing review
            productData.reviews[userReviewIndex] = newReview;
          } else {
            // If the user hasn't sent a review, push the new review
            productData.reviews.push(newReview);
          }
      
          // Update the product document with the updated reviews array
          await updateDoc(doc(db, 'products', id), { reviews: productData.reviews,});
          getDetail()
          
          setLoading(false);
          closeReview();
          toast.success('Votre avis a bien été enregistré', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
          } else {
          console.error('Product document does not exist.');
        }
      } catch (error) {
        console.error('Error updating product:', error);
      }
    };

    const openReview = () =>{
        if(localStorage.getItem("user")){
            setReview(true)
        }else{
            toast.warn('Connectez vous pour pouvoir donner votre avis', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
        }
    }

    const getUser = async (id)=>{
        const data= await getDocs(usersRef)
        const filteredData = data.docs.map((doc) => (
            {
                ...doc.data(),
            }
        ) )
        const user = filteredData.find((user) => user.id === id);
        setNom(user?.nom)
        setPrenom(user?.prenom)
    }
    
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user && user.emailVerified) {
            localStorage.setItem('user', JSON.stringify(user));
          }
        });
    
        return () => {
          unsubscribe();
        };
      }, []);






    return (
        <div className={`relative flex flex-col items-center justify-center gap-10 ${review?'':''}`} >
            {review && (
                <div onClick={() => closeReview()} className='fixed w-screen h-screen inset-0 flex justify-center items-center bg-[#000000e1] z-[99]' >
                    <div onClick={(e)=> e.stopPropagation()} className='bg-white rounded-md w-[90vw] md:w-[700px] lg:w-[900px] xl:w-[1000px] 2xl:w-[1200px] flex justify-center items-center flex-col gap-7 py-8' >
                        <p className='font-semibold text-[20px] text-[#1E89E4] ' >Note : </p>
                        <div className='flex gap-3' >
                            {loop.map((star, i) => {
                                return(
                                    <img onClick={() => handleRating(i)} src={i<rating?'/images/yellow-star.png' :'/images/gray-star.png'} alt="" className='cursor-pointer w-7 h-7' />
                                )
                            })}
                        </div>
        
                        <p className='font-semibold text-[20px] text-[#1E89E4] ' >Avis : </p>
                        <div className='relative flex flex-col items-center justify-center w-[90%] xl:w-[70%]' >
                            <textarea onChange={(e) => setAvis(e.target.value)} rows='5'  type="text" name="" id="" placeholder='Avis' className='relative w-full p-4 shadow outline-none'/>
                            <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
                        </div>
                        <button onClick={sendReview} className='border border-[#1E89E4] lg:hover:bg-[#1E89E4]  rounded-[10px] text-[#1E89E4] lg:hover:text-white transition duration-300 flex justify-center items-center w-[200px] lg:w-[350px] h-[40px] mt-10 lg:text-[18px] font-medium ' >{loading?'uploading ...':'Envoyer'}</button>
                        {ratingError && (
                            <p className='text-red-500 font-semibold text-[18px]' >La note ne peut pas etre 0</p>
                        )}
                    </div>
                </div>
            )}
            <div className='absolute bottom-0 right-0 triangle2 w-screen h-full bg-[#1E89E4] z-[-1]' ></div>
            <Navbar2 openNav={openNav} setOpenNav={setOpenNav} isAuth={isAuth} signout={signout} />
            <div className='flex flex-col lg:flex-row items-center justify-center gap-3 lg:gap-[100px] xl:gap-[200px] mt-[150px]' >
                <div className='flex flex-col gap-3 p-4 md:gap-6' >
                    <div className='flex items-center gap-3' >
                        <span className='bg-[#1E89E4] w-[5px] h-[40px] ' ></span>
                        <h1 className='text-black font-bold text-[32px] md:text-[38px] lg:text-[40px] xl:text-[44px] tracking-[3px]' >{product.titre}</h1>
                    </div>
                    <div className='flex flex-col gap-2 md:gap-4 lg:gap-3' >
                        <div className='flex items-center gap-3' >
                            <span className='text-[#1E89E4] text-[30px] font-bold' >•</span>
                            <p className='text-black md:text-[18px] lg:text-[20px]' >Largeur : {product.largeur} mm</p>
                        </div>
                        <div className='flex items-center gap-3' >
                            <span className='text-[#1E89E4] text-[30px] font-bold' >•</span>
                            <p className='text-black lg:text-[20px]' >Longueur : {product.longueur} mm</p>
                        </div>
                        <div className='flex items-center gap-3' >
                            <span className='text-[#1E89E4] text-[30px] font-bold' >•</span>
                            <p className='text-black md:text-[18px] lg:text-[20px]' >longueur d'obstacle maximale : {product.longueurObstacleMaximale} mm</p>
                        </div>
                        <div className='flex items-center gap-3' >
                            <span className='text-[#1E89E4] text-[30px] font-bold' >•</span>
                            <p className='text-black md:text-[18px] lg:text-[20px]' >charge maximale : {product.chargeMaximale} kg</p>
                        </div>
                        <div className='flex items-center gap-3' >
                            <span className='text-[#1E89E4] text-[30px] font-bold' >•</span>
                            <p className='text-black md:text-[18px] lg:text-[20px]' >vitesse maximale : {product.vitesseMax} km/h</p>
                        </div>
                        <div className='flex items-center gap-3' >
                            <span className='text-[#1E89E4] text-[30px] font-bold' >•</span>
                            <p className='text-black md:text-[18px] lg:text-[20px]' >classe d'utilisation : {product.classOfUse}</p>
                        </div>
                    </div>
                </div>
                <div className='relative overflow-x-hidden' >
                    <div onClick={handlePrev2} className='absolute left-0 top-[50%] centerY z-[98] cursor-pointer' >
                        <img src="/images/previous.png" alt="" className='w-8 h-8' />
                    </div>
                    <div ref={sliderRef} className='flex items-center  w-[330px] md:w-[400px] lg:w-[400px] xl:w-[500px]' >
                        {product?.images?.map((item)=>{
                            return(
                                <img src={item} alt="" className='flex-shrink-0 w-full' />
                            )
                        })}
                    </div>
                    <div onClick={handleNext2} className='absolute right-0 top-[50%] centerY z-[98] cursor-pointer' >
                        <img src="/images/next.png" alt="" className='w-8 h-8' />
                    </div>
                </div>
            </div>
            <Avis product={product} openReview={openReview}/>
            <div className='rounded-[20px]' >
                <img src="/images/certif.png" alt="" className='w-[80vw] md:w-[70vw] lg:w-[60vw] ' />
            </div>
            <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
        </div>
   
  )
}

export default ProductDetail