import React from 'react'

function Users({users}) {
  return (
    <div className='w-[90vw] lg:w-[90%]  h-[70vh] rounded-[20px] shadow bg-white overflow-x-scroll p-4' >
                  <div className='flex items-center gap-6 mt-10 lg:justify-center' >
                    <p className='text-[#1E89E4] font-semibold w-[200px] flex-shrink-0 text-[22px]' >ID</p>
                    <p className='text-[#1E89E4] font-semibold w-[200px] flex-shrink-0 text-[22px]' >Nom</p>
                    <p className='text-[#1E89E4] font-semibold w-[200px] flex-shrink-0 text-[22px]' >Prenom</p>
                    <p className='text-[#1E89E4] font-semibold w-[200px] flex-shrink-0 text-[22px]' >Email</p>
                  </div>
                  <div className='w-full h-[2px] bg-[#1E89E4] mt-6' ></div>
                  {users.map((user, i)=>{
                    return(
                      <div key={i} className='flex items-center gap-6 mt-10 lg:justify-center' >
                        <p className='text-black font-normal w-[200px] flex-shrink-0' >{user.id}</p>
                        <p className='text-black font-normal w-[200px] flex-shrink-0' >{user.nom}</p>
                        <p className='text-black font-normal w-[200px] flex-shrink-0' >{user.prenom}</p>
                        <p className='text-black font-normal w-[200px] flex-shrink-0' >{user.email}</p>
                      </div>
                    )
                  })}
    </div>
  )
}

export default Users