import {React, useEffect, useState, useRef} from 'react'
import '../App.css'
import ReviewCard from './ReviewCard'
function Avis({product, openReview}) {

    const [average, setAverage] = useState(0)
    const [loop, setLoop] = useState([1,2,3,4,5])
    const [index, setIndex] = useState(0)
    const sliderRef = useRef(null)

    
  
  
    useEffect(() => {
        if (product && product.reviews && Array.isArray(product.reviews) && product.reviews.length > 0){
            let totalRating= 0
            for (const review of product.reviews) {
              totalRating += review.note;
            }
            const averageRating = totalRating / product.reviews.length;  
            setAverage(Math.floor(averageRating))
        }
        
    }, [product])

    const handleNext = () => {
      const n = Math.floor(product.reviews.length/3 )  
      if(product.reviews.length % 3 === 0){
        if(index >= n -1){
          let size = sliderRef.current.offsetWidth;
          let newIndex = 0;
          sliderRef.current.style.transition = 'transform 0.6s linear';
          setIndex(newIndex);
          sliderRef.current.style.transform = 'translateX(' + (-size * newIndex) + 'px)';
    
        }else{
          let size = sliderRef.current.offsetWidth;
          let newIndex = index + 1;
          sliderRef.current.style.transition = 'transform 0.6s linear';
          setIndex(newIndex);
          sliderRef.current.style.transform = 'translateX(' + (-size * newIndex) + 'px)';
        }
      }else{
        if(index >= n){
          let size = sliderRef.current.offsetWidth;
          let newIndex = 0;
          sliderRef.current.style.transition = 'transform 0.6s linear';
          setIndex(newIndex);
          sliderRef.current.style.transform = 'translateX(' + (-size * newIndex) + 'px)';
    
        }else{
          let size = sliderRef.current.offsetWidth;
          let newIndex = index + 1;
          sliderRef.current.style.transition = 'transform 0.6s linear';
          setIndex(newIndex);
          sliderRef.current.style.transform = 'translateX(' + (-size * newIndex) + 'px)';
        }
      }
  }

  const handlePrev = () => { 
    const n = Math.floor(product.reviews.length/3 )   
    if(index === 0){
      let size = sliderRef.current.offsetWidth;
      let newIndex = product.reviews.length % 3 === 0? n-1: n ;
      sliderRef.current.style.transition = 'transform 0.6s linear';
      setIndex(newIndex);
      sliderRef.current.style.transform = 'translateX(' + (-size * newIndex) + 'px)';
    }else{
      let size = sliderRef.current.offsetWidth;
      let newIndex = index - 1;
      sliderRef.current.style.transition = 'transform 0.6s linear';
      setIndex(newIndex);
      sliderRef.current.style.transform = 'translateX(' + (-size * newIndex) + 'px)';
    }
  }



  return (
    <div className='bg-white w-[90vw] rounded-[38px] flex flex-col justify-center items-center gap-4 py-10 shadow' >
        <p className='text-black font-bold text-[20px] lg:text-[24px]' >Avis de nos clients</p>
        <div className='flex gap-3' >
                    {loop.map((star, i) => {
                        return(
                            <img key={i} src={i<average?'/images/yellow-star.png' :'/images/gray-star.png'} alt="" className='w-7 h-7' />
                        )
                    })}
        </div>
        <div  className='relative flex justify-center w-full overflow-x-hidden' >
          {product && product.reviews?.length>0 && (
            <div onClick={handlePrev} className={`${index!=0  ?'lg:flex':'hidden'} absolute left-3 top-[50%] centerY z-[98] cursor-pointer bg-[#1E89E4] w-[40px] h-[40px] rounded-full justify-center items-center  `} >
                <img src="/images/white-previous.png" alt="" className='w-6 h-6' />
            </div>
          )}
            <div ref={sliderRef} className='flex items-center w-[90%] gap-4 py-4 overflow-x-scroll lg:overflow-x-visible' >
                    {product?.reviews?.map((review, i) =>{
                        return(
                            <ReviewCard nom={review.nom} prenom={review.prenom} note={review.note} avis={review.avis} i={i} />
                        )
                    })}
                    {/* {loop.map((review, i) =>{
                        return(
                            <ReviewCard nom='dadad' prenom='dadada' note='4' avis='dsadasdadadada' i={i} />
                        )
                    })} */}
            </div>
            {product && product.reviews?.length>0 && (
            <div onClick={handleNext} className={`${product && product.reviews ?'lg:flex':'hidden'} absolute right-3 top-[50%] centerY z-[98] cursor-pointer bg-[#1E89E4] w-[40px] h-[40px] rounded-full  justify-center items-center`} >
                        <img src="/images/white-next.png" alt="" className='w-6 h-6' />
            </div>
            ) }
        </div>

        <button onClick={() => openReview()} className='text-white bg-[#1E89E4] border lg:border-[#1E89E4] lg:hover:bg-white  rounded-[35px] cursor-pointer   lg:hover:text-[#1E89E4] transition duration-300 flex justify-center items-center w-[200px] lg:w-[300px] h-[45px] xl:h-[55px] lg:text-[18px] font-medium ' >Donner mon avis</button>
    </div>
  )
}

export default Avis