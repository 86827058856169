import React, { useEffect, useState } from 'react'
import { doc, getDoc, updateDoc, getDocs, collection } from "firebase/firestore";
import {db} from '../config/firebase'

function UpdateProduct({
    updatedTitle,
    setUpdatedTitle,
    updatedPrice,
    setUpdatedPrice,
    updatedDescription,
    setUpdatedDescription,
    updatedModele,
    setUpdatedModele,
    updatedClassOfUse,
    setUpdatedClassOfUse,
    updatedLargeur,
    setUpdatedLargeur,
    updatedLongueur,
    setUpdatedLongueur,
    updatedVitesseMax,
    setUpdatedVitesseMax,
    updatedChargeMaximale,
    setUpdatedChargeMaximale,
    updatedLongueurObstacleMaximale,
    setUpdatedLongueurObstacleMaximale,
    loading,
    addProduct,
    filePickerRef,
    handleUpdatedFileSelect,
    updatedSelectedFiles,
    setUpdatedSelectedFiles,
    updateProduct,
    id,
    openCurrency,
    setOpenCurrency,
    updatedCurrency,
    setUpdatedCurrency
  }) {

    const [images, setImages] = useState([])

    useEffect ( () => {
        console.log(updatedTitle)
    }, [updatedTitle])

    // useEffect(() => {
    //   setUpdatedSelectedFiles([])
    // }, [])

    async function getData(id){
      try{
          const docRef = doc(db, 'products', id)
          const docSnap = await getDoc(docRef)
          const data = docSnap.data()
          console.log(data)
          setImages(data.images)
          setUpdatedTitle(data.titre)
          setUpdatedPrice(data.prix)
          setUpdatedClassOfUse(data.classOfUse)
          setUpdatedLargeur(data.largeur)
          setUpdatedLongueur(data.longueur)
          setUpdatedVitesseMax(data.vitesseMax)
          setUpdatedChargeMaximale(data.chargeMaximale)
          setUpdatedLongueurObstacleMaximale(data.longueurObstacleMaximale)
          setUpdatedCurrency(data.devise)
          
      }catch(e){
          console.log("No such document!");
      }
  }

  useEffect(()=>{
    getData(id)
  }, [])

  return (
    <div className=' flex flex-col items-center lg:flex-row gap-10 xl:gap-[100px] rounded-[20px]   p-4' >
    <div className='flex flex-col gap-4' >

      <div className='flex flex-col gap-4' >
        <p className='text-[18px] font-semibold' >Titre</p>
        <div className='relative text-[14px]' >
              <input onChange={(e)=> setUpdatedTitle(e.target.value)} value={updatedTitle} placeholder='Titre' className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'/>
              <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
        </div>
      </div>

      <div className='flex flex-col gap-4' >
        <p className='text-[18px] font-semibold' >Prix </p>
        <div className='relative text-[14px]' >
              <input onChange={(e)=> setUpdatedPrice(e.target.value)} value={updatedPrice} placeholder='Prix' className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'/>
              <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
        </div>
      </div>
      <div className='flex flex-col gap-1 cursor-pointer' >
            <div onClick={() => setOpenCurrency(!openCurrency)} className='w-[260px] sm:w-[320px] md:w-[360px] h-[50px] border border-[#1E89E4] rounded-[10px] flex items-center justify-between p-4 ' >
                <p>{updatedCurrency}</p>
                <img src="/images/dropdown.png" alt="" className='w-6' />
            </div>
            <div className={`flex flex-col gap-2 w-[260px] sm:w-[320px] md:w-[360px] border border-[#1E89E4] rounded-[10px] py-4  ${openCurrency?'h-auto':'hidden h-0 '}`} >
                              <p onClick={() => {
                                  setOpenCurrency(false)
                                  setUpdatedCurrency("DZD")
                              }} className='text-[#1E89E4] hover:bg-[rgba(30,136,228,0.43)] h-[30px] flex items-center px-4' >DZD</p>
                              <p onClick={() => {
                                  setOpenCurrency(false)
                                  setUpdatedCurrency("EUR")
                              }} className='text-[#1E89E4] hover:bg-[rgba(30,136,228,0.43)] h-[30px] flex items-center px-4' >EUR</p>
                                                            <p onClick={() => {
                                  setOpenCurrency(false)
                                  setUpdatedCurrency("USD")
                              }} className='text-[#1E89E4] hover:bg-[rgba(30,136,228,0.43)] h-[30px] flex items-center px-4' >USD</p>
              </div>
      </div>

      {/* <div className='flex flex-col gap-4' >
        <p className='text-[18px] font-semibold' >Modele </p>
        <div className='relative text-[14px]'>
          <input
            onChange={(e) => setUpdatedModele(e.target.value)}
            placeholder='Modele'
            className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
            value={updatedModele}
          />
          <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
        </div>
      </div> */}

      <div className='flex flex-col gap-4' >
        
        <p className='text-[18px] font-semibold' >Classe </p>
        <div className='relative text-[14px]'>
          <input
            onChange={(e) => setUpdatedClassOfUse(e.target.value)}
            placeholder='classe'
            className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
            value={updatedClassOfUse}
          />
          <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
        </div>
      </div>

      <div className='flex flex-col gap-4' >
        <p className='text-[18px] font-semibold' >largeur </p>
        <div className='relative text-[14px]'>
          <input
            onChange={(e) => setUpdatedLargeur(e.target.value)}
            placeholder='largeur'
            className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
            value={updatedLargeur}
          />
          <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
        </div>
      </div>

      <div className='flex flex-col gap-4' >
        <p className='text-[18px] font-semibold' >Longueur</p>
        <div className='relative text-[14px]'>
          <input
            onChange={(e) => setUpdatedLongueur(e.target.value)}
            placeholder='longueur'
            className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
            value={updatedLongueur}
          />
          <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
        </div>
      </div>

      <div className='flex flex-col gap-4' >
        <p className='text-[18px] font-semibold' >Vitesse max</p>
        <div className='relative text-[14px]'>
          <input
            onChange={(e) => setUpdatedVitesseMax(e.target.value)}
            placeholder='vitesse max'
            className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
            value={updatedVitesseMax}
          />
          <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
        </div>
      </div>

      <div className='flex flex-col gap-4' >
        <p className='text-[18px] font-semibold' >Charge max</p>
        <div className='relative text-[14px]'>
          <input
            onChange={(e) => setUpdatedChargeMaximale(e.target.value)}
            placeholder='charge max'
            className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
            value={updatedChargeMaximale}
          />
          <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
        </div>
      </div>

      <div className='flex flex-col gap-4' >
        <p className='text-[18px] font-semibold' >Longueur obstacle max</p>
        <div className='relative text-[14px]'>
          <input
            onChange={(e) => setUpdatedLongueurObstacleMaximale(e.target.value)}
            placeholder='longueur obstacle max'
            className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
            value={updatedLongueurObstacleMaximale}
          />
          <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
        </div>
      </div>

      {/* <div className='relative' >
        <textarea onChange={(e)=> setUpdatedDescription(e.target.value)} rows='5'  type="text" name="" id="" placeholder='Description' className='w-[90vw] lg:w-[400px] xl:w-[600px] sm:w-[320px] md:w-[360px]  relative  outline-none'/>
        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
      </div> */}
      <button onClick={() => updateProduct(id) } className={`bg-[#1E89E4] hover:bg-white border  border-[#1E89E4] transition duration-300 rounded-md h-[40px] w-[200px] flex justify-center items-center  hover:text-[#1E89E4] mx-auto lg:mx-0 ${loading?'bg-white text-[#1E89E4] ': 'text-white'}`} >{loading?'uploading...':'Modifier'}</button>
      <input ref={filePickerRef} onChange={handleUpdatedFileSelect} type="file" multiple className='hidden w-0 h-0' />
    </div>
    <div className='flex flex-col gap-8 max-w-[400px]' >
      <div onClick={() => {filePickerRef.current.click()}} className='w-[90vw] lg:w-[400px] h-[300px] flex flex-col justify-center items-center cursor-pointer bg-white border-[2px] border-dotted border-gray-400 rounded-[24px]' >
          <img src="/images/upload-pic.png" alt="" className='w-[150px]' />
          <p className='text-[18px] font-medium text-gray-400' >{updatedSelectedFiles.length===0?'Modifiez vos images':`Vous avez selectionné ${updatedSelectedFiles.length} images`}</p>
      </div>
     
    </div>
</div>
  )
}

export default UpdateProduct