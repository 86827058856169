import React, { useState } from 'react'
import '../App.css'

function Banques() {

    const [loop, setLoop] = useState([1,2,3,4,5,6])

  return (
    <div className='flex flex-col items-center gap-6 lg:gap-9 mt-[40px] lg:mt-[65px]' >
        <div className='relative w-auto' >
            <p className='text-black font-semibold text-[20px] lg:text-[24px] xl:text-[28px]' >Nos banques partenaires</p>
            <div className='w-full h-[2px] bg-[#1E89E4] rounded-sm absolute bottom-[-10px]' ></div>
        </div>


        <div className='w-full overflow-x-hidden' >
            <div className='flex infinite2  items-center w-full gap-10 py-4 sm:gap-x-[80px] md:gap-x-[100px] lg:gap-[60px] xl:gap-[80px] overflow-x-visible' >
                {loop.map((logo, i) => {
                    return(
                        <img src={`/images/banque${i+1}.png`} alt="" className='w-[calc(100%/3-40px)] sm:w-[calc(100%/4-80px)] md:w-[calc(100%/4-100px)] lg:w-[calc(100%/6-60px)] xl:w-[calc(100%/6-80px)]' />
                    )
                })} 
                {loop.map((logo, i) => {
                    return(
                        <img src={`/images/banque${i+1}.png`} alt="" className='w-[calc(100%/3-40px)] sm:w-[calc(100%/4-80px)] md:w-[calc(100%/4-100px)] lg:w-[calc(100%/6-60px)] xl:w-[calc(100%/6-80px)]' />
                    )
                })} 
            </div>
        </div>


    </div>
  )
}

export default Banques