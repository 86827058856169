import React from 'react'
import Navbar from './Navbar'
import { useState } from 'react'
import '../App.css'


function Hero({openNav, setOpenNav}) {



  return (
    <div className='bg-[url("../public/images/mobile-hero2.png")] lg:bg-[url("../public/images/hero2.png")] bg-cover h-[800px] sm:h-[700px] lg:h-[650px] relative triangle' >

    </div>
  )
}

export default Hero