import React from 'react'
import Navbar2 from '../components/Navbar2'

function ReceiveEmail({openNav,setOpenNav,isAuth, signout}) {
  return (
    <div className='relative flex flex-col w-screen h-[100vh] lg:h-[100vh]' >
        <Navbar2 openNav={openNav} setOpenNav={setOpenNav} isAuth={isAuth} signout={signout} ></Navbar2>
        <div className='w-screen h-[50%] relative flex justify-center items-start lg:items-center' >
            <img src="/images/login.png" alt="" className='absolute inset-0 object-cover w-full h-full' />
            <div className='w-full h-full absolute inset-0 bg-[rgba(30,137,228,0.9)]' ></div>
        </div>
        
        <div className='h-[50%] w-screen  bg-white' ></div>
        
        <div className='py-14 px-4 absolute w-[90vw] md:w-[700px] height-tr rounded-[30px] shadow top-[50%] left-[50%] centerAll bg-white flex flex-col justify-center items-center gap-8' >
            <p className='text-[24px] font-bold text-[rgba(30,137,228,1)] text-center' >Merci pour votre message !</p>
            <p className='text-[20px] text-[rgba(30,137,228,1)] leading-[35px] text-center' >Nous avons bien reçu votre e-mail et nous vous répondrons dans les plus brefs délais.<br></br> Votre satisfaction est notre priorité.</p>
        </div>


    </div>

  )
}

export default ReceiveEmail