import React from 'react'

function Chair() {
  return (
    <div className='absolute top-0 w-screen  h-[800px] sm:h-[700px] lg:h-[650px] flex flex-col lg:gap-[60px] xl:gap-[150px] 2xl:gap-[200px] lg:flex-row justify-center items-center px-6 mt-[100px] lg:mt-[20px]' >
        <img src="/images/p4.png" alt="" className='order-2 lg:order-1 h-[400px] sm:h-[450px] md:h-[400px] lg:h-[500px] xl:h-[620px] md:mt-[60px] lg:mt-[120px]' />
        <div className='order-1 lg:order-2 flex flex-col items-start gap-4 lg:gap-7 md:w-[550px] lg:w-[500px]'  >
            <div className='flex items-center justify-start gap-3 ' >
                <span className='bg-white w-[5px] h-[30px] ' ></span>
                <p className='text-white lg:text-[18px] md:text-[20px]' >Qui sommes nous ?</p>
            </div>
            <h1 className='text-white font-bold text-[40px] md:text-[44px] xl:text-[44px]' >INNOVALOGIE</h1>
            <p className='text-white text-[18px] lg:text-[20px] lg:leading-[35px]' >est un acteur de la mobilité électrique sur le territoire national algérien, accompagné de sa diaspora et de partenariats avec des PME internationales, dans des secteurs très pointus. </p>
            <p className='text-white text-[12px] md:text-[14px] lg:text-[16px] font-extralight italic' >“Les sujets liés à l’inclusion sont au cœur de nos préoccupations au quotidien.”</p>
        </div>
    </div>
  )
}

export default Chair