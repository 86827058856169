import {React, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'

function DashNav({active, setActive, setUpdatedSelectedFiles, getProducts}) {


   const clickProduct = () =>{
    setActive('2')
    getProducts()
   }  

  return (
    <div className='fixed bottom-0 flex lg:flex-col justify-center items-center gap-10 w-screen h-[90px] bg-[#1E89E4] lg:h-full lg:w-[130px] lg:left-0 z-[96]' >
        {window.innerWidth>1024 && (
                    <Link to='/' ><img src="/images/logo.png" alt="" className='hidden w-0 lg:w-full lg:flex' /></Link>
        )}
       
        <div onClick={()=>setActive('1')} className={`w-[95px]  lg:w-[90%] h-[80px] lg:h-[90px] flex flex-col items-center justify-center gap-2 rounded-[10px] p-2 cursor-pointer ${active==='1'?'bg-white':''}`} >
            <img src={`${active==='1'?'/images/blue-user.png':'/images/user.png'}`} alt=""  className='w-5 h-5 lg:w-7 lg:h-7' />
            <p className={`${active==='1'?'text-[#1E89E4]':'text-white'}`} >utilisateurs</p>    
        </div>
        <div onClick={()=> clickProduct() } className={`w-[95px] lg:w-[90%] h-[80px] lg:h-[90px] flex flex-col items-center justify-center gap-2 rounded-[10px] p-2 cursor-pointer ${active==='2'?'bg-white':''}`} >
            <img src={`${active==='2'?'/images/blue-product.png':'/images/product.png'}`} alt=""  className='w-5 h-5 lg:w-7 lg:h-7' />
            <p className={`${active==='2'?'text-[#1E89E4]':'text-white'}`} >produits</p>    
        </div>
        <div onClick={()=>setActive('3')} className={`w-[95px] lg:w-[90%] h-[80px] lg:h-[90px] flex flex-col items-center justify-center gap-2 rounded-[10px] p-2 cursor-pointer ${active==='3'?'bg-white':''}`} >
            <img src={`${active==='3'?'/images/blue-add.png':'/images/add.png'}`} alt=""  className='w-5 h-5 lg:w-7 lg:h-7' />
            <p className={`${active==='3'?'text-[#1E89E4]':'text-white'}`} >ajouter</p>    
        </div>

    </div>
  )
}

export default DashNav