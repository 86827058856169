import React, { useEffect, useState } from 'react'
import '../App.css'
import { Link } from 'react-router-dom'

function ProductCard({title, price, description, images, id, reviews}) {

  const [average, setAverage] = useState(0)
  const [loop, setLoop] = useState([1,2,3,4,5])


  useEffect(() => {
    let totalRating= 0
    for (const review of reviews) {
      totalRating += review.note;
    }
    const averageRating = totalRating / reviews.length;  
    setAverage(Math.floor(averageRating))
  },[])

  return (
    <Link to={`/ProductDetail/${id}`} className='h-[450px] w-[320px] rounded-[20px] bg-white shadow overflow-hidden ' >
        <div className='flex flex-col items-center justify-center h-full gap-4 ' >
          <img src={images[0]} alt="" className='w-full h-[55%]  object-contain' />
          <div className='flex flex-col items-center gap-5 ' >
            <h1 className='font-semibold text-[24px] tracking-[2px] text-center text-[#1E89E4]' >{title}</h1>
            <p className='text-black font-medium text-[20px] text-center' >{price}</p>
          </div>
          <div className='flex gap-3' >
                        {loop.map((star, i) => {
                            return(
                                <img src={i<average?'/images/yellow-star.png' :'/images/gray-star.png'} alt="" className='w-5 h-5 cursor-pointer' />
                            )
                        })}
            </div>
        </div>
    </Link>
  )
}

export default ProductCard