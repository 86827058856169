import React from "react";
import { Link } from "react-router-dom";
import "../App.css";
import { useNavigate } from 'react-router-dom'

function Navbar({ openNav, setOpenNav, isAuth, signout }) {

    const navigate = useNavigate()



  return (
    <div className="absolute top-0 h-[90px] w-screen bg-transparent flex p-5 lg:px-10 justify-between items-center z-[99] ">
      <Link to="/"><img src="/images/logo.png" alt="" className="h-[70px]" /></Link>
      <div className="flex justify-center gap-6 ">
          <div
            className={`flex flex-col justify-center gap-1 w-[30px] cursor-pointer lg:hidden z-[99]`}
            onClick={() => setOpenNav(!openNav)}>
                <span
                className={`h-[2px]  bg-white rounded-[8px] transition-all ${
                    openNav ? "rotate-45 translate-y-[6px] " : ""
                }`}
                ></span>
                <span
                className={`h-[2px]  bg-white rounded-[8px] transition-all ${
                    openNav ? "opacity-0" : ""
                }`}
                ></span>
                <span
                className={`h-[2px]  bg-white rounded-[8px] transition-all  ${
                    openNav ? "-rotate-45 -translate-y-[6px] " : "w-[20px]"
                } `}
                ></span>
          </div>
      </div>

      <div className={`bg-[#1E89E4] lg:bg-transparent absolute lg:static top-0 left-0   flex flex-col lg:flex-row items-center justify-center lg:justify-center  w-screen lg:w-auto h-screen lg:h-full gap-10 transition-transform duration-500 z-[98]  ${
            openNav ? "translate-y-0" : "translate-y-[-100%] lg:translate-y-0"
            }`}>
        
            <a href="#Acceuil" onClick={() => setOpenNav(false)}>
                <p
                    className={`nav-element relative text-white  transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                    openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                    }`}
                >
                Accueil
                </p>
            </a>
            <Link to="/Produits" onClick={() => setOpenNav(false)}>
                <p
                    className={`nav-element relative text-white  transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                    openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                    }`}
                >
                Produits
                </p>
            </Link>
            <Link to="/Form" onClick={() => setOpenNav(false)}>
                <p
                    className={`nav-element relative text-white  transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                    openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                    }`}
                >
                Contact
                </p>
            </Link>
            {
                localStorage.getItem("admin")==='true' && (
                    <Link to={`/Dashboard/${0}`}  onClick={() => setOpenNav(false)}>
                    <p
                        className={`nav-element relative text-white  transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                        openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                        }`}
                    >
                    Dashboard
                    </p>
                </Link>
                )
            }
          
            {localStorage.getItem("isAuth")==='true' ? (

   
            <button onClick={() => signout()}>
                <p
                    className={`nav-element relative text-white  transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                    openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                    }`}
                >
                    Se deconnecter
                </p>
            </button>
            ): (
                <Link to='/Login' onClick={() => setOpenNav(false)}>
                    <p
                        className={`nav-element relative text-white  transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                        openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                        }`}
                    >
                        Se connecter
                    </p>
                </Link>
            )}



      </div>
    </div>
  );
}

export default Navbar;
