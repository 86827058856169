import React, { useRef } from 'react'
import DashNav from '../components/DashNav'
import { useState, useEffect } from 'react'
import DashboardCard from '../components/DashboardCard'
import '../App.css'
import { db, storage } from '../config/firebase'
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import { getDocs, collection, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar2 from '../components/Navbar2'
import AddProduct from '../components/AddProduct'
import Users from '../components/Users'
import UpdateProduct from '../components/UpdateProduct'
import { useParams } from 'react-router-dom'

function Dashboard({users, productsCollectionRef, products, getProducts, openNav, setOpenNav, signout, getUsers}) {

    const {id}= useParams();  
    const [active, setActive] = useState("1")
    const [selectedFiles, setSelectedFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('')
    const [price, setPrice] = useState(0)
    const [description, setDescription] = useState('')
    const [modele, setModele] = useState('');
    const [classOfUse, setClassOfUse] = useState('');
    const [largeur, setLargeur] = useState('');
    const [longueur, setLongueur] = useState('');
    const [vitesseMax, setVitesseMax] = useState('');
    const [chargeMaximale, setChargeMaximale] = useState('');
    const [longueurObstacleMaximale, setLongueurObstacleMaximale] = useState('');
    const [openCurrency, setOpenCurrency] = useState(false)
    const [currency, setCurrency] = useState("Devise")

    const [updatedSelectedFiles, setUpdatedSelectedFiles] = useState([])
    const [updatedTitle, setUpdatedTitle] = useState('');
    const [updatedPrice, setUpdatedPrice] = useState(0);
    const [updatedDescription, setUpdatedDescription] = useState('');
    const [updatedModele, setUpdatedModele] = useState('');
    const [updatedClassOfUse, setUpdatedClassOfUse] = useState('');
    const [updatedLargeur, setUpdatedLargeur] = useState('');
    const [updatedLongueur, setUpdatedLongueur] = useState('');
    const [updatedVitesseMax, setUpdatedVitesseMax] = useState('');
    const [updatedChargeMaximale, setUpdatedChargeMaximale] = useState('');
    const [updatedLongueurObstacleMaximale, setUpdatedLongueurObstacleMaximale] = useState('');
    const [updatedCurrency, setUpdatedCurrency] = useState("")

    const filePickerRef = useRef(null)
    
    const handleFileSelect = async (e) => {
      setSelectedFiles(Array.from(e.target.files))
    };
    const handleUpdatedFileSelect = async (e) => {
      setUpdatedSelectedFiles(Array.from(e.target.files))
    };

    const addProduct = async () => {
      if (loading) return;
    
      setLoading(true);
      const downloadURLs = [];
    
      for (const file of selectedFiles) {
        const fileRef = ref(storage, `products/${file.name}`);
        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
        downloadURLs.push(downloadURL);
      }
    
      await addDoc(productsCollectionRef, {
        titre: title,
        prix: price,
        images: downloadURLs, 
        modele: modele,
        classOfUse: classOfUse,
        largeur: largeur,
        longueur: longueur,
        vitesseMax: vitesseMax,
        chargeMaximale: chargeMaximale,
        longueurObstacleMaximale: longueurObstacleMaximale,
        reviews: [],
        devise: currency,
      });
      setLoading(false);
      toast.success('Le produit a bien été enregistré', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    };
    
    const deleteProduct = async (id) =>{
      const productDoc = doc(db, 'products', id)
      await deleteDoc(productDoc)
      getProducts()
      toast.success('Le produit a bien été supprimé', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }

    const updateProduct = async (id) =>{
      if (loading) return;
    
      setLoading(true);

      const downloadURLs = [];
    
      for (const file of updatedSelectedFiles) {
        const fileRef = ref(storage, `products/${file.name}`);
        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
        downloadURLs.push(downloadURL);
      }
      const productDoc = doc(db, 'products', id)
      await updateDoc(productDoc, {
        ... (downloadURLs.length>0 && {images : downloadURLs}),
        ...(updatedTitle && { titre: updatedTitle }),
        ...(updatedPrice && { prix: updatedPrice }),
        ...(updatedModele && { modele: updatedModele }),
        ...(updatedClassOfUse && { classOfUse: updatedClassOfUse }),
        ...(updatedLargeur && { largeur: updatedLargeur }),
        ...(updatedLongueur && { longueur: updatedLongueur }),
        ...(updatedVitesseMax && { vitesseMax: updatedVitesseMax }),
        ...(updatedChargeMaximale && { chargeMaximale: updatedChargeMaximale }),
        ...(updatedLongueurObstacleMaximale && { longueurObstacleMaximale: updatedLongueurObstacleMaximale }),
        ...(updatedCurrency && { devise: updatedCurrency }),
      });
      
      getProducts()
      setLoading(false);
      toast.success('Le produit a bien été modifié', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }

    useEffect(()=>{
      getProducts()
    } , [])

    useEffect(()=>{
      getUsers()
    } , [])

    useEffect(() => {
      if (openNav) {
        document.body.classList.add('hide');
      } else {
        document.body.classList.remove('hide');
      }
  
      // Clean up the class on unmount
      return () => {
        document.body.classList.remove('hide');
      };
    }, [openNav]);


    if(localStorage.getItem("admin")==='true'){


      return (
        <div className='relative flex items-center justify-center' >
          
          {window.innerWidth<1024 && (
            <Navbar2 openNav={openNav} setOpenNav={setOpenNav} signout={signout} />
          )}
            <DashNav active={active} setActive={setActive} setUpdatedSelectedFiles={setUpdatedSelectedFiles} getProducts={getProducts} />
              <div className='flex flex-col items-center justify-center gap-10  w-screen lg:w-[calc(100%-130px)] lg:ml-[130px] mt-[100px] mb-[100px]'>
                <p className='text-black font-bold text-center text-[22px]' >{active==='1'?'Utilisateurs':active==='2'?"Produits": active==="3" ? "Ajouter un Produit" : "Modifier Produit"}</p>
                {active==='1'?(
                  <Users users= {users} />
                ):active==='2'?(
    
                  <div className='flex flex-wrap justify-center w-full gap-4 mx-auto' >
                        {products.map((product, i)=>{
                            return(
                                <DashboardCard key={i} title={product.titre} price={product.prix} devise={product.devise} description={product.description} images={product.images} id={product.id} deleteProduct={deleteProduct} updateProduct={updateProduct} setActive = {setActive} />
                            )
                        })}
                  </div>            ): active === "3" ? 
                  
                  (
                    <AddProduct
                      title={title}
                      setTitle={setTitle}
                      price={price}
                      setPrice={setPrice}
                      description={description}
                      setDescription={setDescription}
                      modele={modele}
                      setModele={setModele}
                      classOfUse={classOfUse}
                      setClassOfUse={setClassOfUse}
                      largeur={largeur}
                      setLargeur={setLargeur}
                      longueur={longueur}
                      setLongueur={setLongueur}
                      vitesseMax={vitesseMax}
                      setVitesseMax={setVitesseMax}
                      chargeMaximale={chargeMaximale}
                      setChargeMaximale={setChargeMaximale}
                      longueurObstacleMaximale={longueurObstacleMaximale}
                      setLongueurObstacleMaximale={setLongueurObstacleMaximale}
                      loading= {loading}
                      addProduct={addProduct}
                      filePickerRef ={filePickerRef}
                      handleFileSelect ={handleFileSelect}
                      selectedFiles = {selectedFiles}
                      openCurrency={openCurrency}
                      setOpenCurrency={setOpenCurrency}
                      currency={currency}
                      setCurrency = {setCurrency}
                    />           
                ) : (
                  <UpdateProduct
                    updatedTitle={updatedTitle}
                    setUpdatedTitle={setUpdatedTitle}
                    updatedPrice={updatedPrice}
                    setUpdatedPrice={setUpdatedPrice}
                    updatedDescription={updatedDescription}
                    setUpdatedDescription={setUpdatedDescription}
                    updatedModele={updatedModele}
                    setUpdatedModele={setUpdatedModele}
                    updatedClassOfUse={updatedClassOfUse}
                    setUpdatedClassOfUse={setUpdatedClassOfUse}
                    updatedLargeur={updatedLargeur}
                    setUpdatedLargeur={setUpdatedLargeur}
                    updatedLongueur={updatedLongueur}
                    setUpdatedLongueur={setUpdatedLongueur}
                    updatedVitesseMax={updatedVitesseMax}
                    setUpdatedVitesseMax={setUpdatedVitesseMax}
                    updatedChargeMaximale={updatedChargeMaximale}
                    setUpdatedChargeMaximale={setUpdatedChargeMaximale}
                    updatedLongueurObstacleMaximale={updatedLongueurObstacleMaximale}
                    setUpdatedLongueurObstacleMaximale={setUpdatedLongueurObstacleMaximale}
                    loading={loading}
                    addProduct={addProduct}
                    filePickerRef={filePickerRef}
                    handleFileSelect={handleFileSelect}
                    updatedSelectedFiles={updatedSelectedFiles}
                    setUpdatedSelectedFiles={setUpdatedSelectedFiles}
                    handleUpdatedFileSelect={handleUpdatedFileSelect}
                    updateProduct = {updateProduct}
                    id= {id}
                    openCurrency={openCurrency}
                    setOpenCurrency={setOpenCurrency}
                    updatedCurrency={updatedCurrency}
                    setUpdatedCurrency = {setUpdatedCurrency}
                  />


                )}
              </div>
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                />
            </div>
      )
    }else{
      return null
    }

}

export default Dashboard


