import { useEffect, useState } from 'react';
import './App.css';
import MainPage from './pages/MainPage';
import {Route, Routes} from 'react-router-dom';
import Login from './pages/Login';
import {signOut } from 'firebase/auth'
import {auth} from './config/firebase'
import { useNavigate } from 'react-router-dom'
import Form from './pages/Form';
import Products from './pages/Products';
import Dashboard from './pages/Dashboard';
import { getDocs, collection, addDoc } from 'firebase/firestore'
import { db } from './config/firebase';
import ProductDetail from './pages/ProductDetail';
import ReceiveEmail from './pages/ReceiveEmail';


function App() {

  const [isAuth, setIsAuth] = useState(false)
  const [openNav, setOpenNav] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginError, setLoginError] = useState(false)
  const [users, setUsers] = useState([])
  const [products, setProducts] = useState([])
  const [review, setReview] = useState(false)
  const usersCollectionRef = collection(db, "users")
  const productsCollectionRef = collection(db, "products")


  const navigate = useNavigate()


  const signout = async () =>{
    try{
        await signOut(auth, email, password)
        setOpenNav(false)
        localStorage.setItem("isAuth", 'false')
        localStorage.removeItem("user");
        localStorage.removeItem("admin")
        navigate('/Login')
    }catch(e){
        console.error(e)
        setLoginError(false)
    }
}

const getUsers = async ()=>{
  try{
      const data= await getDocs(usersCollectionRef)
      const filteredData = data.docs.map((doc) => (
          {
              ...doc.data(),
              id : doc.id
          }
      ) )
      setUsers(filteredData)
  } catch(e){
      console.error(e)
  }
}

const getProducts = async ()=>{
  try{
      const data= await getDocs(productsCollectionRef)
      const filteredData = data.docs.map((doc) => (
          {
              ...doc.data(),
              id : doc.id
          }
      ) )
      setProducts(filteredData)
  } catch(e){
      console.error(e)
  }
}

  return (
    <div className={`${openNav ? "overflow-y-hidden":''}`}>
      <Routes>
        <Route path='/' element={<MainPage openNav={openNav} setOpenNav={setOpenNav} isAuth={isAuth} signout={signout} />} ></Route>
        <Route path='/Login' element={<Login isAuth={isAuth} setIsAuth={setIsAuth} openNav={openNav} setOpenNav={setOpenNav} signout={signout} email={email} setEmail={setEmail} password={password} setPassword={setPassword} loginError={loginError} setLoginError={setLoginError}  />} ></Route>
        <Route path='/Form' element={<Form openNav={openNav} setOpenNav={setOpenNav} isAuth={isAuth} signout={signout}/>} ></Route>
        <Route path='/ReceiveEmail' element={<ReceiveEmail openNav={openNav} setOpenNav={setOpenNav} isAuth={isAuth} signout={signout}/>} ></Route>
        <Route path='/Produits' element={<Products openNav={openNav} setOpenNav={setOpenNav} isAuth={isAuth} signout={signout} products={products} getProducts={getProducts} />} ></Route>
        <Route path='Dashboard/:id' element={<Dashboard users={users} productsCollectionRef={productsCollectionRef} products={products} getProducts={getProducts} openNav={openNav} setOpenNav={setOpenNav} signout={signout} getUsers={getUsers} />} ></Route>
        <Route path='ProductDetail/:id' element={<ProductDetail openNav={openNav} setOpenNav={setOpenNav} isAuth={isAuth} signout={signout} review={review} setReview={setReview} />} ></Route>
      </Routes>
    </div>
  );
}

export default App;
