import React, { useEffect } from 'react'
import Hero from '../components/Hero'
import Innovation from '../components/Innovation'
import Contact from '../components/Contact'
import Chair from '../components/Chair'
import Navbar from '../components/Navbar'
import Partenaires from '../components/Partenaires'
import Banques from '../components/Banques'

function MainPage({openNav,setOpenNav,isAuth, signout}) {

  useEffect(() => {
    if (openNav) {
      document.body.classList.add('hide');
    } else {
      document.body.classList.remove('hide');
    }

    // Clean up the class on unmount
    return () => {
      document.body.classList.remove('hide');
    };
  }, [openNav]);




  return (
    <div className={`relative ${openNav ? "overflow-y-hidden":''}`} >
        <Navbar openNav={openNav} setOpenNav={setOpenNav} isAuth={isAuth} signout={signout} />
        <Hero/>
        <Chair/>
        <Innovation/>
        <Partenaires/>
        <Banques/>
        <Contact/>
    </div>
  )
}

export default MainPage