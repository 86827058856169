import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from 'firebase/auth'
import {getFirestore} from 'firebase/firestore'
import { getStorage } from "firebase/storage";


// db1
const firebaseConfig = {
  apiKey: "AIzaSyB5ofcPcJ3M5pbH_YT4R2DL0BdSgul4dsM",
  authDomain: "innovalogie-88ce1.firebaseapp.com",
  projectId: "innovalogie-88ce1",
  storageBucket: "innovalogie-88ce1.appspot.com",
  messagingSenderId: "404535763363",
  appId: "1:404535763363:web:a86b8aa6844d1cfd177a16"
};

// db2
// const firebaseConfig = {
//   apiKey: "AIzaSyBswlVCGZIHDqIGBzu1Ft04E_rf_O6HOgg",
//   authDomain: "innotest-39ae6.firebaseapp.com",
//   projectId: "innotest-39ae6",
//   storageBucket: "innotest-39ae6.appspot.com",
//   messagingSenderId: "1057235015058",
//   appId: "1:1057235015058:web:5aaae5bd20b28bb559dd4b"
// };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider()
export const db = getFirestore(app)
export const storage = getStorage(app)