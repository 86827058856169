import React, { useEffect, useState } from 'react'

function AddProduct({
    title,
    setTitle,
    price,
    setPrice,
    description,
    setDescription,
    modele,
    setModele,
    classOfUse,
    setClassOfUse,
    largeur,
    setLargeur,
    longueur,
    setLongueur,
    vitesseMax,
    setVitesseMax,
    chargeMaximale,
    setChargeMaximale,
    longueurObstacleMaximale,
    setLongueurObstacleMaximale,
    loading,
    addProduct,
    filePickerRef,
    handleFileSelect,
    selectedFiles,
    downloadURLs,
    openCurrency,
    setOpenCurrency,
    currency,
    setCurrency
  }) {

    useEffect(()=>{
      console.log(downloadURLs)
    }, [])


  return (
    <div className=' flex flex-col items-center lg:flex-row gap-10 xl:gap-[100px] rounded-[20px]   p-4' >
    <div className='flex flex-col gap-7' >
      <div className='relative text-[14px]' >
            <input onChange={(e)=> setTitle(e.target.value)} placeholder='Titre' className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'/>
            <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
      </div>
      <div className='relative text-[14px]' >
            <input onChange={(e)=> setPrice(e.target.value)} placeholder='Prix' className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'/>
            <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
      </div>
      <div className='flex flex-col gap-1 cursor-pointer' >
            <div onClick={() => setOpenCurrency(!openCurrency)} className='w-[260px] sm:w-[320px] md:w-[360px] h-[50px] border border-[#1E89E4] rounded-[10px] flex items-center justify-between p-4 ' >
                <p>{currency}</p>
                <img src="/images/dropdown.png" alt="" className='w-6' />
            </div>
            <div className={`flex flex-col gap-2 w-[260px] sm:w-[320px] md:w-[360px] border border-[#1E89E4] rounded-[10px] py-4  ${openCurrency?'h-auto':'hidden h-0 '}`} >
                              <p onClick={() => {
                                  setOpenCurrency(false)
                                  setCurrency("DZD")
                              }} className='text-[#1E89E4] hover:bg-[rgba(30,136,228,0.43)] h-[30px] flex items-center px-4' >DZD</p>
                              <p onClick={() => {
                                  setOpenCurrency(false)
                                  setCurrency("EUR")
                              }} className='text-[#1E89E4] hover:bg-[rgba(30,136,228,0.43)] h-[30px] flex items-center px-4' >EUR</p>
                                                            <p onClick={() => {
                                  setOpenCurrency(false)
                                  setCurrency("USD")
                              }} className='text-[#1E89E4] hover:bg-[rgba(30,136,228,0.43)] h-[30px] flex items-center px-4' >USD</p>
              </div>
      </div>
      {/* <div className='relative text-[14px]'>
        <input
          onChange={(e) => setModele(e.target.value)}
          placeholder='Modele'
          className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
          value={modele}
        />
        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
      </div> */}

      <div className='relative text-[14px]'>
        <input
          onChange={(e) => setClassOfUse(e.target.value)}
          placeholder='classe'
          className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
          value={classOfUse}
        />
        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
      </div>

      <div className='relative text-[14px]'>
        <input
          onChange={(e) => setLargeur(e.target.value)}
          placeholder='largeur'
          className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
          value={largeur}
        />
        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
      </div>

      <div className='relative text-[14px]'>
        <input
          onChange={(e) => setLongueur(e.target.value)}
          placeholder='longueur'
          className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
          value={longueur}
        />
        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
      </div>

      <div className='relative text-[14px]'>
        <input
          onChange={(e) => setVitesseMax(e.target.value)}
          placeholder='vitesse max'
          className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
          value={vitesseMax}
        />
        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
      </div>

      <div className='relative text-[14px]'>
        <input
          onChange={(e) => setChargeMaximale(e.target.value)}
          placeholder='charge max'
          className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
          value={chargeMaximale}
        />
        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
      </div>

      <div className='relative text-[14px]'>
        <input
          onChange={(e) => setLongueurObstacleMaximale(e.target.value)}
          placeholder='longueur obstacle max'
          className='w-[90vw] lg:w-[400px] xl:w-[600px] h-[50px] relative  outline-none'
          value={longueurObstacleMaximale}
        />
        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
      </div>

      {/* <div className='relative' >
        <textarea onChange={(e)=> setDescription(e.target.value)} rows='5'  type="text" name="" id="" placeholder='Description' className='w-[90vw] lg:w-[400px] xl:w-[600px] sm:w-[320px] md:w-[360px]  relative  outline-none'/>
        <div className='absolute bottom-0 z-[99] h-[2px] w-full bg-[#1E89E4]' ></div>
      </div> */}
      <button onClick={addProduct} className={`bg-[#1E89E4] hover:bg-white border  border-[#1E89E4] transition duration-300 rounded-md h-[40px] w-[200px] flex justify-center items-center  hover:text-[#1E89E4] mx-auto lg:mx-0 ${loading?'bg-white text-[#1E89E4] ': 'text-white'}`} >{loading?'uploading...':'Ajouter'}</button>
      <input ref={filePickerRef} onChange={handleFileSelect} type="file" multiple className='hidden w-0 h-0' />
    </div>

  <div className='flex flex-col gap-6 max-w-[400px]' >
    <div onClick={() => {filePickerRef.current.click()}} className='w-[90vw] lg:w-[400px] h-[300px] flex flex-col justify-center items-center cursor-pointer bg-white border-[2px] border-dotted border-gray-400 rounded-[24px]' >
        <img src="/images/upload-pic.png" alt="" className='w-[150px]' />
        <p className='text-[18px] font-medium text-gray-400' >{selectedFiles.length===0?'Telechargez vos images':`Vous avez selectionné ${selectedFiles.length} images`}</p>
    </div>
    {/* <div className='flex flex-wrap gap-6' >
        {selectedFiles && selectedFiles.map((file, index)=>{
          return(
            <img src={URL.createObjectURL(file)} className='rounded-[20px] h-[80px]' />
          )
        })}

    </div> */}

  </div>
</div>
  )
}

export default AddProduct