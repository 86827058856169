import React from 'react'
import { Link } from 'react-router-dom'

function Contact() {
  return (
    <div id='Contact' className='relative w-[95vw] sm:w-[500px] md:w-[750px] lg:w-[900px] xl:w-[1000px] px-4 py-8 rounded-[20px]  mx-auto my-10 flex flex-col justify-center items-center gap-6 overflow-hidden' >
        <img src="/images/contact.png" alt="" className='w-full h-full absolute inset-0 rounded-[20px] object-cover' />
        <div className='w-full h-full absolute inset-0 bg-[rgba(30,137,228,0.9)] rounded-[20px]' ></div>
        <h1 className='text-white font-bold text-[22px] sm:text-[24px] md:text-[26px] z-[99]' >Nous Contacter</h1>
        <p className='w-[300px] sm:w-[400px] md:w-[600px] lg:w-[800px] xl:w-[900px] text-center text-white z-[99] text-[14px] leading-[22px] ' >Nous sommes ravis de vous entendre. N'hésitez pas à nous contacter pour discuter de vos besoins, répondre à vos questions ou simplement échanger. Votre message est notre priorité.</p>
        <Link to='/Form' className='flex justify-center items-center bg-white hover:bg-[rgba(255,255,255,0.9)] transition-all text-black font-semibold rounded-[22px] p-4 z-[99] w-[180px] sm:w-[200px] md:w-[250px] lg:w-[300px]'>Nous Contacter</Link>
    </div>
  )
}

export default Contact