import React from "react";
import { Link } from "react-router-dom";
import "../App.css";
import { useLocation } from 'react-router-dom';


function Navbar2({openNav, setOpenNav, isAuth, signout}) {

    const location = useLocation();
    const pathname = location.pathname;
    const specialBackgroundPaths = ['/Login', '/Form', '/ReceiveEmail'];

    return (
        <div className="absolute top-0 h-[90px] w-screen bg-transparent flex p-5 lg:px-10 justify-between items-center z-[99] ">
          <Link to="/"><img src={pathname==='/Produits' || pathname.startsWith('/ProductDetail') ?'/images/blue-logo.png':'/images/logo.png'} alt="" className="h-[70px]" /></Link>
          <div className="flex justify-center gap-6 ">
              <div
                className={`flex flex-col justify-center gap-1 w-[30px] cursor-pointer lg:hidden z-[99]`}
                onClick={() => setOpenNav(!openNav)}>
                    <span
                    className={`h-[2px]  ${specialBackgroundPaths.includes(pathname) ? 'bg-white' : 'bg-[#1E89E4]'}  rounded-[8px] transition-all ${
                        openNav ? "rotate-45 translate-y-[6px] bg-white " : ""
                    }`}
                    ></span>
                    <span
                    className={`h-[2px] ${specialBackgroundPaths.includes(pathname) ? 'bg-white' : 'bg-[#1E89E4]'}  rounded-[8px] transition-all ${
                        openNav ? "opacity-0" : ""
                    }`}
                    ></span>
                    <span
                    className={`h-[2px] ${specialBackgroundPaths.includes(pathname) ? 'bg-white' : 'bg-[#1E89E4]'}  rounded-[8px] transition-all  ${
                        openNav ? "-rotate-45 -translate-y-[6px] bg-white" : "w-[20px]"
                    } `}
                    ></span>
              </div>
          </div>
    
          <div className={`bg-[#1E89E4] lg:bg-transparent absolute lg:static top-0 left-0   flex flex-col lg:flex-row items-center justify-center lg:justify-center  w-screen lg:w-auto h-screen lg:h-full gap-10 transition-transform duration-500 z-[98]  ${
                openNav ? "translate-y-0" : "translate-y-[-100%] lg:translate-y-0"
                }`}>
            
                <Link to='/' onClick={() => setOpenNav(false)}>
                    <p
                        className={` relative text-white  ${pathname==='/Form' || pathname==='/ReceiveEmail' ?"nav-element":'lg:text-[#1E89E4] nav-element2'}  transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                        openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                        }`}
                    >
                    Accueil
                    </p>
                </Link>
                <Link to="/Produits" onClick={() => setOpenNav(false)}>
                <p
                    className={` relative ${pathname==='/Form' || pathname==='/ReceiveEmail' ?"nav-element":'lg:text-[#1E89E4] nav-element2'} text-white  transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                    openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                    }`}
                >
                Produits
                </p>
                </Link>
                <Link to="/Form" onClick={() => setOpenNav(false)}>
                    <p
                        className={` relative text-white ${pathname==='/Form' || pathname==='/ReceiveEmail' ?"nav-element":'lg:text-[#1E89E4] nav-element2'}   transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                        openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                        }`}
                    >
                    Contact
                    </p>
                </Link>

                {
                localStorage.getItem("admin")==='true' && (
                    <Link to={`/Dashboard/${0}`}  onClick={() => setOpenNav(false)}>
                    <p
                        className={` relative text-white ${pathname==='/Form' || pathname==='/ReceiveEmail' ?"nav-element":'lg:text-[#1E89E4] nav-element2'}  transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                            openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                            }`}
                    >
                    Dashboard
                    </p>
                </Link>
                )
            }
                {localStorage.getItem("isAuth")==='true' ? (
                    <Link  onClick={() => signout()}>
                        <p
                            className={` relative text-white ${pathname==='/Form' || pathname==='/ReceiveEmail'?"nav-element":'lg:text-[#1E89E4] nav-element2'}  transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                            openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                            }`}
                        >
                            Se deconnecter
                        </p>
                    </Link>
                ): (
    
                <Link to='/Login' onClick={() => setOpenNav(false)}>
                    <p
                        className={`relative text-white ${pathname==='/Form' || pathname==='/ReceiveEmail'?"nav-element":'lg:text-[#1E89E4] nav-element2'}   transition-all lg:duration-200 text-[20px] lg:text-[16px] font-bold  cursor-pointer duration-500 ${
                        openNav ? "translate-x-0" : "translate-x-[-300%] lg:translate-x-0"
                        }`}
                    >
                        Se connecter
                    </p>
                </Link>
           
                )}
          </div>
        </div>
      );
    }


export default Navbar2