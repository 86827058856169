import {React, useState, useEffect} from 'react'
import '../App.css'

function ReviewCard({nom, prenom, note, avis, i }) {


    const [loop, setLoop] = useState([1,2,3,4,5])


  return (
    <div className={` flex flex-col  justify-center gap-5 w-[90%] md:w-[400px] lg:w-[calc(100%/3-20px)] h-[200px] xl:h-[250px]  rounded-[20px] shadow bg-white flex-shrink-0 p-5`}>
            <div className='flex items-center gap-3' >
                <img src='/images/review-user.png' className='w-[40px] h-[40px] rounded-full' />
                <div>
                    <p className='font-semibold text-black' >{nom} {prenom}</p>
                    <div className='flex gap-1' >
                        {loop.map((star, i) => {
                            return(
                                <img key={i} src={i<note?'/images/yellow-star.png' :'/images/gray-star.png'} alt="" className='w-4 h-4' />
                            )
                        })}
                    </div>
                </div>
            </div>
            <p className='font-medium text-black' >{avis}</p>
    </div>
  )
}

export default ReviewCard